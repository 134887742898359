.evali-app {
  display: grid;
  grid-template-columns: 13rem 1fr;
  grid-template-rows: 3rem 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-areas:
    "sidebar header"
    "sidebar outer-main";

  background-color: $white-ter;

  &__main {
    padding: 2rem;
    display: grid;
    grid-template-columns: 58rem 20rem;
    grid-template-rows: min-content 1fr;
    grid-column-gap: 2rem;
    grid-template-areas:
      "header header"
      "main sidebar-extra";
  }
  &__header {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
    grid-area: header;
    height: 4rem;
  }
  .main-content {
    grid-template-areas: ".";
    grid-area: main;
    h1 {
      font-size: 1.25rem;
    }
    h1 + p {
      margin-top: -1rem;
    }
    .box + .box {
      margin-top: 0.5rem;
    }
  }

  .sidebar {
    grid-area: sidebar-extra;
    &__section {
      margin-bottom: 2rem;
    }
    &__section:last-of-type {
      margin-bottom: 0;
    }
    &__title {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    > .box {
      margin-bottom: 0.5rem;
    }
    > .box:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1536px) {
    &__main {
      grid-template-columns: 1fr 20rem;
    }
  }
}

.evali-sidebar {
  //padding-top: 2rem;
  min-height: 100vh;
  height: 100%;
  grid-area: sidebar;
  z-index: 1;

  background-color: white;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1), 2px 0 4px rgba(0, 0, 0, 0.1);

  overflow-y: hidden;

  &__start {
    position: sticky;
    top: 1rem;
  }

  &__logo {
    display: block;
    margin: 1rem auto;
  }

  &__item {
    padding: 1rem;
    display: flex;
    align-items: center;
    color: black;

    span {
      margin-left: 1rem;
    }
    &:hover {
      background-color: $white-ter;
    }
  }
  &__item--active {
    background-color: $white-ter;
    //color: $clr-blue;
  }
  a:hover {
    color: $clr-purple;
  }
}

.evali-header {
  grid-area: header;
  height: 2.5rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  &__primarymenu {
    height: 100%;
  }
  &__endmenu {
    display: flex;
  }

  &__item {
    margin: 0 0.75rem;
    font-size: 0.875rem;
  }
  &__item:last-of-type {
    margin-right: 0;
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &__btn {
    outline: 0;
    border: 0;

    height: 100%;

    cursor: pointer;

    padding: 0 1rem;
    color: $clr-black-text-light;
    border-right: 1px solid $grey-lighter;

    font-size: 1rem;

    background-color: white;

    &:hover {
      background-color: $white-ter;
    }

    &--primary {
      background-color: white;
      color: $clr-primary;
      font-weight: $weight-bold;
      &:hover {
      }
    }
  }
  &__btn:last-of-type {
    border-right: none;
  }
}

.fieldset > legend {
  width: 100%;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: $weight-bold;
}

#form-modal,
#confirm-modal {
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 31; // over Bulma navbar

  display: none;

  background-color: rgba(0, 0, 0, 0.4);

  &.is-active {
    display: block;
  }

  .form {
    @include raised-box;
    margin: 4rem auto;
    width: 36rem;

    &__header {
      border-bottom: 1px solid $grey-light;
      font-size: 0.875rem;
      font-weight: $weight-bold;
      position: relative;
      margin: -2rem -2rem 2rem;
      padding: 1rem 2rem;
    }

    .field {
      margin-bottom: 2rem;
    }
    .btns {
      margin-top: 2rem;
    }
    &__content {
      max-width: 30rem;
      margin: 0 auto;
    }
    &--wide {
      width: 46rem;
    }
    &--extrawide {
      width: 80rem;
    }
  }
}

.evali-modal-close {
  border: none;
  outline: none;
  background: none;

  position: absolute;
  right: 20px;
  top: 12px;
  width: 32px;
  height: 32px;

  opacity: 0.5;

  cursor: pointer;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
  &:before,
  &:after {
    position: absolute;
    top: 8px;
    left: 15px;
    content: " ";
    height: 16px;
    width: 2px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:active {
    &:before,
    &:after {
      background-color: white;
    }
  }
}

.confirm {
  width: 20rem;
  padding: 2rem;
  margin: 4rem auto;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.2);
  &--wide {
    width: 40rem;
  }
  &__title {
    font-weight: $weight-bold;
    margin-bottom: 1rem;
  }
}

.icon {
  transition: transform 0.3s ease-in-out;
  &--interactive {
    cursor: pointer;
    padding: 0.25rem;
    &:hover {
      color: $clr-blue;
    }
  }
  &--inline {
    display: inline-block;
  }
  &--rotate {
    transform: rotate(540deg);
  }
}

.admin-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;

  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);

  &__wrapper {
    margin: 2rem auto;
    max-width: 50rem;
    background-color: white;
  }

  &__content {
    padding: 2rem 4rem;
  }

  footer {
    padding: 2rem 0;
  }
}
