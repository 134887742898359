/*
@keyframes moveBg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
*/

.test-body {
  background-color: $white-ter;
  //background: radial-gradient(
  //    ellipse at top left,
  //    lighten($clr-purple, 20%) 0%,
  //    transparent 50%
  //  ),
  //  radial-gradient(
  //    ellipse at top right,
  //    lighten($clr-blue, 10%) 0%,
  //    transparent 50%
  //  ),
  //  radial-gradient(
  //    ellipse at bottom,
  //    lighten($clr-pink, 5%) 0%,
  //    transparent 25%
  //  );
  //background-position: 0% 0%;
  //background-size: 200% 200%;
  min-height: 100vh;
  //animation: moveBg 15s ease infinite;
}
