// COLORS
$clr-pink: #ffbdcb;
$clr-blue: #92dcf0;
$clr-midnight: #051d3e;
$clr-yellow: #f6e829;
$clr-green: #65e3ab;
$clr-purple: #9674f9;
$clr-dark-purple: #271d40;
$clr-black: #020202;
$clr-red: #e80a3b;
$clr-grey: #efefef;
$clr-dark-grey: #d6d6d6;
$clr-grey-green: #cbe0dd;

$clr-black-text: rgba(0, 0, 0, 0.87);
$clr-black-text-light: rgba(0, 0, 0, 0.75);
$clr-grey-text: rgba(0, 0, 0, 0.7);

//$clr-primary: #3636b1;
//$clr-primary: #235f70;

//$clr-primary: #2d6791;
//$clr-primary: #0a86d8;
$clr-primary: #0765b5;
