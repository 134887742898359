.evali-btn {
  height: 3rem;
  min-width: 12rem;
  margin: 0 auto;

  font-weight: $weight-bold;
  font-size: 1rem;

  outline: 0;
  border: none;
  cursor: pointer;

  color: $clr-black-text;

  box-shadow: 0px 1rem 2rem rgba(77, 57, 128, 0.15);

  border-radius: 1.5rem;
  background-color: white;
  border: 1px solid $grey-light;

  &--primary {
    background-color: $clr-blue;
    border-color: $grey-light;
  }

  &--centered {
    display: block;
  }

  &--wide {
    width: 100%;
  }

  &:hover {
    box-shadow: 0px 1rem 2rem rgba(77, 57, 128, 0.15),
      0 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 0.5rem 1rem rgba(77, 57, 128, 0.15);
  }

  &:disabled {
    background-color: $white;
    border: 1px solid $grey-lighter;
    cursor: not-allowed;
    box-shadow: none;
    color: $grey-light;
  }

  &.is-loading {
    color: transparent !important;
    pointer-events: none;
    position: relative;
    &::after {
      @extend %loader;
      position: absolute !important;
      top: 1rem;
      left: calc(50% - 0.5rem);
      border-left-color: $clr-purple;
    }
  }
}
