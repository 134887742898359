@mixin raised-box($padding: 2rem, $sharp: false) {
  border-radius: 0.25rem;
  background-color: white;
  padding: $padding;
  @if $sharp {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.14);
  } @else {
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.2);
  }
}

@mixin raised-box-active($padding: 2rem, $sharp: false) {
  border-radius: 0.25rem;
  background-color: white;
  padding: $padding;
  @if $sharp {
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.14),
      0 1px 4px rgba(0, 0, 0, 0.16);
  } @else {
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.3);
  }
}
