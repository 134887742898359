.progress-bar {
  width: 36rem;
  margin: 0 auto;
  height: 0.5rem;

  position: relative;
  background-color: white;
  border-radius: 0.25rem;

  overflow: hidden;

  &__margin {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 40rem) {
    width: 20rem;
  }
  @media screen and (max-width: 26rem) {
    margin-top: 1rem;
    width: 16rem;
  }

  &__fill {
    //background-color: $clr-green;
    background: linear-gradient(to right, $clr-pink, $clr-purple);

    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.5rem 0 rgba(255, 255, 255, 0.9);

    position: absolute;
    //top: 0;
    width: 100%;
    height: 0.5rem;
    transform: translateX(-100%);
    transition: transform 0.25s ease-in-out;
  }
}

.step-progress-bar {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  &__line {
    position: absolute;
    top: 20%;
    left: 0;
    height: 0.5rem;
    width: 100%;
    background-color: $clr-grey-green;
    border-radius: 0.5rem;
    &--filled {
      background-color: $clr-green;
      z-index: 1;
    }
    &--disabled {
      background-color: $clr-grey;
    }
    &--filled-disabled {
      background-color: $clr-dark-grey;
    }
  }
  &__ball__container {
    position: absolute;
    top: -0.5rem;
    z-index: 2;
  }
  &__ball {
    position: absolute;
    &__icon {
      color: $clr-primary;
      top: 0.25rem;
      left: 0.25rem;
      z-index: 4;
      &--disabled {
        color: $clr-midnight;
      }
    }
    &--outer {
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      background-color: white;
      border: 6px solid $clr-grey-green;
      &--disabled {
        border: 6px solid $clr-grey;
      }
    }
    &--full {
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      background-color: $clr-green;
      z-index: 3;
    }
    &--partial-left {
      left: 0;
      width: 1rem;
      height: 2rem;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border-right: 0;
      background-color: $clr-green;
      z-index: 3;
    }
    &--partial-right {
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      border: 6px solid $clr-grey-green;
      background-color: white;
      z-index: 2;
    }
    &__partial-container {
      position: relative;
      width: 2rem;
    }
    &--disabled {
      background-color: $clr-dark-grey;
    }
  }
}
