.shared-link {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  // display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: 0.1rem;
  margin: 0 0 1rem 0;
  border-radius: 5px;
  &:hover {
    font-weight: 500;
    // background-color: rgba(0, 0, 0, 0.03);
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__modal-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
.shared-link-title {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0.1rem;
  border-radius: 5px;
}
