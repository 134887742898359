$btn-radius: 1rem;

.btns {
  margin-top: 1rem;
}

.btn {
  background-color: white;
  border: 1px solid $grey-light;
  border-radius: $btn-radius;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $clr-black-text;
  text-align: center;

  padding: 0.3rem 1rem;
  margin: 0 0.5rem 0.5rem 0;

  user-select: none;
  white-space: nowrap;

  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    box-shadow 0.22s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    border-color: $grey;
    color: $clr-primary;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
    box-shadow: none;
  }

  &-primary {
    background-color: $clr-primary;
    border-color: $clr-primary;
    color: white;
    &:hover {
      border-color: lighten($clr-primary, 5%);
      background-color: lighten($clr-primary, 5%);
      color: white;
    }
  }
  &-warning {
    background-color: $clr-red;
    border-color: $clr-red;
    color: white;
    &:hover {
      border-color: lighten($clr-red, 5%);
      background-color: lighten($clr-red, 5%);
      color: white;
    }
  }
  &-link {
    border-color: transparent;
    color: $clr-primary;
    &:focus,
    &:hover {
      border-color: transparent;
      background-color: $white-ter;
      color: darken($clr-primary, 4%);
    }
  }
  &-login {
    margin-top: 0.5rem;
    width: 100%;
    border-radius: 0.25rem;
    border-color: $clr-purple;
    background-color: $white-ter;
    color: $clr-black-text;
    font-weight: $weight-bold;
    &.is-loading {
      color: transparent;
    }
  }
  &-greyflush {
    background-color: $white-ter;
    color: $clr-grey-text;
    border: 1px solid transparent;
    box-shadow: none;
    &:hover {
      color: $clr-grey-text;
      background-color: darken($white-ter, 5%);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
      border-color: darken($white-ter, 5%);
    }
  }
  &-greyflush--checked {
    border: 1px solid $clr-black-text;
    &:hover {
      border-color: $clr-black-text;
    }
  }

  &:disabled,
  &-link:disabled,
  &-primary:disabled {
    border-color: $grey-light;
    background-color: $white-ter;
    color: $clr-grey-text;
    cursor: not-allowed;
  }
  &-link:disabled {
    border-color: transparent;
    background-color: transparent;
  }

  &-flyout {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      border-color: $clr-primary;
    }
  }
  &.is-loading {
    position: relative;
    color: transparent;
    &:after {
      @extend %loader;
      position: absolute;
      top: 0.6rem;
      left: calc(50% - 0.5rem);
    }
  }
  &-tight {
    margin: 0;
    border-width: 0;
  }
  &-tinytext {
    line-height: 1.25rem;
    font-size: 0.875rem;
  }
}

.btn-group {
  .btn {
    margin: 0;
    border-radius: 0;
    border-left-width: 0;
    &:hover {
      border-color: $grey-light;
    }
  }
  .btn:first-of-type {
    border-top-left-radius: $btn-radius;
    border-bottom-left-radius: $btn-radius;
    border-left-width: 1px;
    &:hover {
      border-color: $clr-primary;
    }
  }
  .btn:last-of-type {
    border-top-right-radius: $btn-radius;
    border-bottom-right-radius: $btn-radius;
  }
}
