.answer-wrapper {
  display: flex;
  flex-direction: column;

  align-items: center;

  @include mobile {
    min-height: 70vh;
  }
}
.answer {
  flex-grow: 0;

  width: 36rem;
  margin-top: 4rem;

  ul {
    list-style: none;
    margin-left: 0;

    display: flex;
    justify-content: space-between;

    li {
      margin: 0;

      button {
        border: 3px solid $clr-pink;
        border-radius: 50%;
        display: block;
        background-color: transparent;
        text-align: center;

        width: 3rem;
        height: 3rem;

        cursor: pointer;

        padding: 0.5rem;
        text-align: center;

        font-size: 1rem;

        &:focus,
        &:active {
          outline: none;
        }

        &:focus,
        &:hover {
          //background: radial-gradient(lighten($clr-pink, 5%), transparent);
          border: 4px solid white;
          background-color: lighten($clr-pink, 7%);
        }
        &:active {
          border: 4px solid white;
          background-color: $clr-pink;
        }

        &.is-active {
          background-image: none;
          border: 4px solid white;
          background-color: $clr-pink;
        }

        &.is-practice {
          animation: glowing 2s infinite;
          position: relative;
          &::after {
            content: "";
            height: 1rem;
            width: 1rem;
            position: absolute;
            top: -2rem;
            left: 12px;
            border-style: solid;
            border-width: 0.85rem 0.5rem 0 0.5rem;
            border-color: $clr-purple transparent transparent transparent;
          }
        }
      }
    }
  }

  @include mobile {
    margin: 0 1rem;
    width: calc(100% - 1rem);
    max-width: 30rem;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
  }

  &__legend {
    font-weight: $weight-bold;
    display: flex;
    justify-content: space-between;
    margin: 2rem -2rem;
    &--full {
      margin: 2rem -3rem;
      span {
        flex: 1;
        text-align: center;
      }
    }
    @include mobile {
      margin: 2rem 0;
    }
  }
}
.answer__prev,
.answer__next {
  width: 12rem;
  margin: 1rem auto;
  @include mobile {
    width: 6rem;
  }
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -5px $clr-purple;
  }
  40% {
    box-shadow: 0 0 10px $clr-purple;
  }
  60% {
    box-shadow: 0 0 10px $clr-purple;
  }
  100% {
    box-shadow: 0 0 -5px $clr-purple;
  }
}

.test-loader-logo {
  @include until($tablet) {
    display: block;
    margin: 0 auto;
  }
}

.eti-score {
  margin: 2rem 0;
}

.eti-feedback {
  margin: 1rem 0;
}

.eti-legend {
  display: flex;
  justify-content: space-between;
}
