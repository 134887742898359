.respondent {
  display: grid;
  grid-template-columns: 1fr 4fr 6fr 4fr 1fr;
  grid-template-areas: "select person result_decline actionmatch context";

  border-bottom: 2px solid $white-ter;

  margin: 0 -1rem 0.5rem;
  padding: 0.5rem 1rem 1rem;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 2px solid transparent;
  }

  &__person {
    grid-area: person;
  }
  &__result-decline {
    grid-area: result_decline;
  }
  &__readback {
    grid-area: readback;
  }
  &__actionmatch {
    grid-area: actionmatch;
  }

  &--header {
    border-bottom: 1px solid black;
    padding-bottom: 0.5rem;
    div {
      justify-self: center;
      font-size: 0.875rem;
      text-transform: uppercase;
    }
  }
  &__select {
    grid-area: select;
    display: flex;
    align-items: center;
    width: 2rem;
    grid-row: 1 / 4;
  }
  &__name {
    font-weight: $weight-bold;
    align-self: baseline;
  }
  &__email {
    font-size: 0.875rem;
    margin-top: -0.25rem;
    width: 13rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__icon {
    margin: 0 0.25rem;
    svg {
      stroke: $grey-light;
      stroke-width: 1.25;
    }
    &--active {
      svg {
        stroke: hsl(208, 40%, 40%);
        stroke-width: 2;
      }
    }
    &--clickable {
      cursor: pointer;
    }
  }

  &__extra {
    margin-top: 0.5rem;

    display: flex;

    font-size: 0.875rem;
    color: $clr-grey-text;
  }

  &__declined {
    font-weight: $weight-bold;
    color: $clr-red;
  }

  &__tests {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    @media (max-width: 96rem) {
      grid-template-columns: 1fr;
    }
  }

  &__result {
    font-size: 0.875rem;
    min-width: 10rem;
    width: 100%;
    height: 100%;
  }

  &__request {
    span:first-of-type {
      margin-right: 0.5rem;
    }
  }
  &__actions {
    grid-area: context;
    display: flex;
    justify-content: flex-end;
  }
  &__action-signals {
    display: flex;
    margin-bottom: 0.5rem;
  }
  &__match {
    justify-self: left;
  }
  &__partial {
    grid-column: 3;
  }
}

.respondent-score-grid {
  display: grid;
  grid-template-rows: 12rem 2rem;
  &__header {
    display: grid;
    grid-template-columns: repeat(6, 2rem);
    div {
      //display: block;
      //transform: translate(35px, 75px) rotate(315deg);
      //width: 12rem;
      transform: rotate(180deg);
      writing-mode: vertical-rl;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(6, 2rem);
  }
}

.respondent-shared-at {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 0.5rem;
  opacity: 0.3;
}

.eval-admin {
  &__section {
    margin: 1rem 1rem 4rem 1rem;
    padding: 0;
    &__heading {
      margin-top: 1rem;
      font-weight: bold;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: $weight-bold;
    line-height: 1.125;
    &.go-back {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__row-heading {
    font-weight: bold;
    min-height: 4rem;
    padding: 0 1rem;
    background-color: white;
    border-bottom: 2px solid whitesmoke;
    border-radius: 2px;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      stroke: $clr-dark-grey;
    }
  }
  &__row {
    padding: 1rem;
    background-color: white;
    border-bottom: 2px solid whitesmoke;
    &.grid-based {
      display: grid;
      grid-template-areas: "name decline score action selection";
      grid-template-columns: 3fr 2fr 2fr 3fr 1fr;
    }
    &.card {
      padding: 1rem;
      background-color: white;
      border-radius: 2px;
      box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);
      h1 {
        font-size: 1.25rem;
        margin-bottom: 2rem;
        font-weight: $weight-normal;
      }
    }
  }
  &__selected-actions {
    display: flex;
    justify-content: flex-end;
  }
  &__declined-info {
    cursor: help;
  }
}
