.ert-item {
  margin-bottom: 1rem;
  justify-content: flex-start;

  &__row {
    display: flex;
    flex-grow: 0;
    justify-content: center;

    @include mobile {
      margin: 0 -0.5rem;
    }
  }

  &__img {
    margin: 0.5rem;
    border: 1px solid transparent;
    // fixes iOS bug
    height: 100%;

    @media (max-width: 374px) {
      max-width: 80px;
    }
    @media (max-width: 400px) {
      max-width: 120px;
    }
    @media screen and (max-height: 800px) {
      margin: 0.25rem;
    }
  }

  &__matrix-img {
    background-color: white;
    border: 1px solid $grey-light;
    padding: 0.5rem;
  }

  &__img--solution {
    border: 2px solid $clr-green;
  }

  &__textitem {
    font-size: 1.25rem;
  }

  &__txt {
    margin: 0.5rem;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      text-align: center;
      max-width: 6rem;
    }
    @media (max-width: 400px) {
      max-width: 120px;
      max-height: 120px;
    }
  }

  &__answers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include desktop {
      margin: 0 -10rem;
    }
    @media screen and (min-width: 1200px) {
      margin: 0 -14rem;
    }
    @media screen and (min-width: 1300px) {
      margin: 0 -18rem;
    }
    @media screen and (min-width: 1400px) {
      margin: 0 -24rem;
    }
    @media screen and (min-width: 1500px) {
      margin: 0 -28rem;
    }
    @media screen and (min-width: 1600px) {
      margin: 0 -32rem;
    }
  }
  &__matrix-answers {
    border: 1px solid $grey;
    background-color: white;
    padding: 1rem;
  }

  &__answers .ert-item__img,
  &__answers .ert-item__txt,
  &__answers .ert-item__number {
    &:hover {
      border: 1px solid $clr-blue;
      cursor: pointer;
    }
  }

  &__answers .ert-item__img--active,
  &__answers .ert-item__txt--active,
  &__answers .ert-item__number--active {
    border: 2px solid $clr-green;
    &:hover {
      border: 2px solid $clr-green;
    }
  }

  &__text-answers {
    display: flex;
    flex-direction: column;
  }

  &__number {
    font-weight: $weight-bold;
    //font-size: 1.25rem;
    text-align: center;
    margin: 0.25rem auto;
  }

  &__long-txt {
    margin: 0.5rem 0;
    background-color: white;
    border: 1px solid $grey-light;
    border-radius: 1rem;
    padding: 0.5rem;
    text-align: center;
    box-shadow: 0px 1rem 2rem rgba(77, 57, 128, 0.15);
    &--active {
      border-color: $clr-green;
      box-shadow: inset 0 0 0 1px $clr-green;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0px 1rem 2rem rgba(77, 57, 128, 0.15),
        0 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
    }
    &:active {
      transform: translateY(2px);
      box-shadow: 0px 0.5rem 1rem rgba(77, 57, 128, 0.15);
    }
  }
}
.ert-g-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  @media (min-width: 70rem) {
    margin: 0 -10rem;
    flex-direction: row;
  }

  &__matrix {
    margin: 2rem 0;
    @media (min-width: 70rem) {
      margin: 0 1rem;
    }
  }
  &__matrix-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: calc(360px + 1rem);
    @media (max-width: 28rem) {
      min-width: auto;
    }
    @media (max-width: 22rem) {
    }
  }
  &__options {
    display: flex;
    margin: 2rem 0;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
    justify-content: flex-start;
    max-width: calc(480px + 3rem);
    @media (max-width: 40rem) {
      max-width: 100%;
      justify-content: center;
    }
    @media (min-width: 70rem) {
      margin: 0;
    }
  }
  &__image {
    border: 2px solid transparent;
    margin: 0.25rem;
    width: 120px;
    height: 120px;
    @media (max-width: 28rem) {
      width: 96px;
      height: 96px;
    }
    @media (max-width: 24rem) {
      width: 72px;
      height: 72px;
    }
  }
  &__options &__image {
    transition: border-color 0.125s ease-in-out;
    &:hover {
      cursor: pointer;
      border-color: $grey-lighter;
    }
    &--selected {
      border-color: $clr-green;
      &:hover {
        border-color: $clr-green;
      }
    }
  }
}
.dice {
  display: flex;
  align-items: center;
  justify-content: center;
  &-x {
    width: 8rem;
  }
  &-options {
    width: 24rem;
    background-color: white;
    margin: 1rem;
    padding: 0.5rem;
    border: 1px solid $grey-light;
  }
  &-divider {
    width: 1rem;
  }
  &-img {
    border: 2px solid transparent;
    margin: 0;
    &--active {
      border: 2px solid $clr-green;
    }
    &:hover {
      border: 2px solid $clr-blue;
      cursor: pointer;
    }
  }
}
.streak {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  &__box {
    margin: 0.25rem;
    width: 2rem;
    height: 2rem;
    background-color: $white-ter;
    border-radius: 50%;
    border: 1px solid $grey-lighter;
    &--correct {
      background-color: $clr-green;
    }
    &--incorrect {
      background-color: $clr-red;
    }
  }
}
