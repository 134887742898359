.notifications-dashboard-wrapper {
  margin-bottom: 1rem;
  .notifications-dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 10rem;
    grid-gap: 1rem;
    .notification {
      display: grid;
      grid-template-rows: 8fr 2fr;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      padding: 1rem 1rem 0.1rem 1rem;
      background-color: white;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);
      cursor: pointer;
      &:hover {
        box-shadow: none;
      }
      .message {
        -webkit-mask-image: linear-gradient(
          to bottom,
          black 50%,
          transparent 100%
        );
        mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
        overflow-y: hidden;
        margin: 0;
        * {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .footer {
        padding: initial;
        .timestamp {
          display: flex;
          justify-content: flex-end;
          color: rgba(0, 0, 0, 0.8);
          font-size: 0.8rem;
        }
        .overflowing {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .more-dots {
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 2rem;
  }
}

.notification-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  .amount {
    margin-right: 0.2rem;
  }
  .badge {
    display: flex;
  }
}

.notification-page {
  .notification-page-notification {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    .notification-page-notification-content {
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      padding: 1rem 1rem 1rem 2rem;
      border-radius: 0.5rem 0 0 0.5rem;
      position: relative;
      box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);
      background-color: white;
      .content {
        text-overflow: ellipsis;
        overflow-y: hidden;
        margin-top: 1rem;
        ul {
          margin-bottom: 1rem;
        }
      }
      .heading {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .overflow {
          cursor: pointer;
          opacity: 0.8;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .status-bar {
        position: absolute;
        border-radius: 0.5rem 0 0 0.5rem;
        top: 0;
        left: 0;
        height: 100%;
        width: 1rem;
        background-color: $clr-blue;
      }
      .read {
        background-color: $clr-midnight;
        opacity: 0.3;
      }
      .warning {
        background-color: $clr-pink;
      }
    }
    .notification-page-notification-footer {
      display: flex;
      justify-content: space-evenly;
      opacity: 0.6;
      .timestamp-remove {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        .timestamp {
          margin-right: 0.5rem;
        }
        .remove {
          display: flex;
          cursor: pointer;
          align-items: center;
          svg {
            width: 1.1rem;
            height: 1.1rem;
          }
        }
      }
    }
  }
}

@keyframes bell_animation {
  0% {
    transform: rotate(20deg)
  }
  25% {
    transform: rotate(-35deg)
  }
  50% {
    transform: rotate(25deg)
  }
  75% {
    transform: rotate(-15deg)
  }
  100% {
    transform: rotate(5deg)
  }
}

.subscribe_button {
  button {
    display: flex;
  }
  .subscribe_icon {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    cursor: pointer;
    &--active {
      animation-name: bell_animation;
      animation-duration: .75s;
    }
  }
}
