@font-face {
  font-family: "Moderat";
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: url("#{$asset-host}/fonts/Moderat-Regular.eot");
  src: url("#{$asset-host}/fonts/Moderat-Regular.eot?#iefix")
    format("embedded-opentype");
  src: url("#{$asset-host}/fonts/Moderat-Regular.woff") format("woff");
  src: url("#{$asset-host}/fonts/Moderat-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Moderat";
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  src: url("#{$asset-host}/fonts/Moderat-Bold.eot");
  src: url("#{$asset-host}/fonts/Moderat-Bold.eot?#iefix")
    format("embedded-opentype");
  src: url("#{$asset-host}/fonts/Moderat-Bold.woff") format("woff");
  src: url("#{$asset-host}/fonts/Moderat-Bold.woff2") format("woff2");
}

.title {
  font-weight: $weight-bold;
  font-size: 1.5rem;
}
.is-size-6-5 {
  font-size: 0.875rem;
}
