.evali-form {
  label {
    display: block;
    line-height: 1.5rem;
    &.required {
      font-weight: $weight-bold;
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="tel"],
  input[type="email"] {
    padding: 0.75rem 0.5rem;
    width: 100%;

    border-radius: 0.25rem;
    outline: none;
    border: 1px solid $grey-lighter;

    font-size: 1rem;

    &:hover {
      border-color: $grey-light;
    }

    &:focus {
      border-color: $clr-purple;
    }

    &.is-danger {
      border-color: $red;
    }
  }

  .field {
    margin-bottom: 1rem;
    .control {
      .evali-btn {
        min-width: 8rem;
      }
    }
  }

  .form-btns {
    margin-top: 2rem;
  }
}

.evali-select {
  position: relative;

  &::after {
    @extend %arrow;
    right: 1.125rem;
    border-color: $clr-blue;
    z-index: 4;
  }

  label {
    position: absolute;
    top: 1rem;
  }

  select {
    width: 100%;

    cursor: pointer;
    border: 0;
    outline: 0;

    @extend %input;

    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: $grey-lighter;
    border-radius: 0.25rem;
  }
}

.evali-radio {
  label {
    display: inline-block;
    cursor: pointer;
    margin-right: 1rem;

    input {
      margin-right: 0.5rem;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.file-upload-native {
  display: none;
}
.file-upload {
  padding: 4rem;
  border-radius: 1rem;
  border: 0.5rem dashed $grey-lighter;
}

.profile-option {
  margin: 1rem 0;
  border-radius: 0.25rem;
  border: 1px solid $grey-light;
  padding: 1rem;

  cursor: pointer;

  &--selected {
    background-color: $white-ter;
    border-color: $clr-green;
    box-shadow: 0 0 0 1px $clr-green;
  }

  &:hover {
    background-color: $white-ter;
    border-color: $clr-purple;
  }
  &:active {
    border-color: $grey-dark;
  }
}

.download-report-pdf {
  &__selection {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

.context-menu-option > span:first-child {
  display: inline-block;
  width: 1rem;
}

.projects-modal {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.add-to-other-projects-form {
  padding-top: 1rem;
  border-top: 1px solid $grey-lighter;
  &__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}
