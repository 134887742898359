.generic-list {
  background-color: white;
  padding: 1rem;

  &__icon {
    background-color: $white-ter;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    //padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 2rem;
    padding: 0.5rem;
  }
  &__title {
    font-weight: $weight-bold;
  }
  &__vertical-subtitle {
    margin-top: -0.25rem;
  }
  &__select {
    align-self: center;
  }
  &__item--selectmode {
    grid-template-columns: 2rem 1fr;
  }
}

.testtaker-in-list {
  padding: 0 0 1rem;
  background-color: white;
  .testtaker-info {
    display: grid;
    grid-template-columns: 1fr 1fr 8fr 8fr 1fr 1fr;
    grid-template-areas: "select icon name results project extra";
    align-items: center;
    .icon {
      grid-area: icon;
      flex: 1;
    }
    .select {
      grid-area: select;
      flex: 1;
      display: flex;
      align-items: center;
      &::after {
        display: none;
      }
    }
    .name-email {
      grid-area: name;
      flex: 6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        font-weight: $weight-bold;
      }
      .email {
        max-width: 13rem;
        .mute {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .results {
      grid-area: results;
      flex: 6;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .project_relations {
      grid-area: project;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }
    .context_menu {
      grid-area: extra;
      flex: 1;
    }
    // .readback-mode {
    //   flex: 2;
    //   display: flex;
    //   align-items: center;
    // }
    // .comparison {
    //   flex: 4;
    //   justify-content: flex-end;
    //   display: flex;
    // }
  }
}
