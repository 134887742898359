.feedback-report-title {
  margin: 1rem 4rem 2rem;
  text-align: center;
  h2 {
    font-weight: $weight-bold;
    font-size: 1.5rem;
    color: black;
  }
  h3 {
    font-weight: 400;
    font-size: 1.125rem;
    color: $clr-grey-text;
    margin-top: -0.25rem;
  }
}
.feedback-item {
  margin: 2rem auto 6rem;

  padding: 3rem 6rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.14);

  background-color: white;

  &--docked-below {
    background-color: $white-ter;
    margin-top: -6rem;
    border-top: 1px solid $grey-lighter;
  }

  &__result {
    display: none;
  }

  &__legend {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .extreme {
      margin-bottom: 2rem;
    }
    .extreme:last-of-type {
      margin-bottom: 0;
    }
  }

  .subtitle {
    font-size: 1rem;
    font-weight: $weight-bold;
    color: $clr-black;
  }
  .subtitle--feedback-main {
    font-weight: $weight-bold;
    font-size: 2rem;
  }

  &__details {
    .feedback-item__details-content {
      //display: none;
      &::before,
      &::after {
        content: "";
        display: table;
      }
    }
    &--open {
      .feedback-item__details-content {
        //display: block;
      }
    }
  }

  &__your-description {
    margin-top: -1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__general-description {
    margin-bottom: 2rem;
  }

  // tighter modifier for admin
  &--admin {
    margin: 0 auto;
    padding: 1rem 4rem 0;
    box-shadow: none;

    .score-bar--main {
      margin: 1rem auto 2rem;
    }

    .subtitle--feedback-main,
    .subtitle--feedback-match {
      font-size: 1.25rem;
    }
    .facet-intro {
      margin: 1rem 0;
    }
    div.content {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 40rem) {
    padding: 2rem 2rem;
  }
}
.facet-intro {
  cursor: pointer;
  border-top: 2px solid rgba(0, 0, 0, 0.25);
  padding-top: 1rem;
  margin: 3rem 0 3rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 3px;
  display: flex;
  justify-content: space-between;
  &:hover {
    .icon--interactive {
      color: $clr-blue;
    }
  }
}
.feedback-icon {
  color: $clr-purple;
  font-size: 1.25rem;
  &--fail {
    color: $clr-red;
  }
  &--pass {
    color: $clr-green;
  }
  &--exceed {
    color: $clr-purple;
  }
  &--big {
    font-size: 1.25rem;
  }
}
.feedback-your-score {
  margin-bottom: 2rem;
  &__description {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.score {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 4rem;

  &__box {
    border: 2px solid $grey-light;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;

    margin-bottom: 2px;
    &:last-of-type {
      margin-bottom: 0;
    }

    &--near {
      border: 2px solid $grey;
    }
    &--user {
      border: 2px solid $green;
      background-color: $green;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: block;
      }
    }
  }

  @include mobile {
    min-width: 3rem;
  }
}

.score-bar {
  position: relative;
  //background-color: #eee;
  background: linear-gradient(to right, $clr-pink, $clr-purple);
  margin: 0 0 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;

  overflow: hidden;

  display: flex;

  &__fill {
    background: $clr-black-text;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 0.25rem white;

    width: 1.5rem;
    height: 0.5rem;
    transition: transform 0.25s ease-in-out;
  }

  &--nopadding {
    margin: 0.5rem 0;
  }

  &--main {
    margin: 2rem 0;
    height: 1rem;
    border-radius: 0.5rem;
    .score-bar__fill {
      height: 1rem;
      border-radius: 0.5rem;
    }
  }

  &--testexperience {
    margin: 0;
    height: 0.7rem;
    border-radius: 0.7rem;
    flex: 1;
    background: linear-gradient(to right, $clr-pink, $clr-yellow, $clr-blue);
    .score-bar__fill {
      height: 0.7rem;
      border-radius: 0.7rem;
    }
  }
}
.score-bar-threshold {
  margin-top: -1.75rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  &__marker {
    background-color: $clr-green;
    border-radius: 1rem;
    font-size: 0.875rem;
    padding-top: 0.25rem;
    text-transform: uppercase;
    color: $clr-grey-text;
    text-align: center;
  }
}
.result-in-depth-description {
  h2 {
    font-size: 1.5rem;
    color: black;
  }
  ul {
    margin-left: 1rem;
  }
  &__block {
    border-left: 0.75rem solid black;
    padding-left: 1rem;
    margin-bottom: 3rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__block--match {
    border-color: $clr-green;
  }
}
.ridd-your-score {
  font-size: 0.875rem;
  color: $grey;
}

.box-score {
  display: flex;
  $scoreborderradius: 1rem;
  border-radius: $scoreborderradius;
  &__box {
    width: 1rem;
    height: 1rem;
    background-color: $grey-lighter;
    border-radius: $scoreborderradius;
    margin-left: 1px;
  }
  &__box:first-of-type {
    border-top-left-radius: $scoreborderradius;
    border-bottom-left-radius: $scoreborderradius;
    margin-left: 0;
  }
  &__box:last-of-type {
    border-top-right-radius: $scoreborderradius;
    border-bottom-right-radius: $scoreborderradius;
  }
  &__box--filled {
    background-color: $clr-purple;
  }
  &__box--muted {
    background-color: $grey-light;
  }
}

.mini-feedback {
  margin: 1rem 4rem;
  h2 {
    font-size: 1.5rem;
    font-weight: $weight-bold;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1rem;
    font-weight: $weight-bold;
    margin-bottom: 1rem;
  }
  &__items {
    display: grid;
    grid-template-columns: 50% 50%;
    &--centered {
      // special sauce to not break pdf reports.
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .box-score {
      margin-bottom: 0.5rem;
    }
  }
}

.feedback-focus {
  margin: 1rem 0 1rem;

  &--main {
    margin: -1rem 0 1rem;
    .feedback-focus__header {
      margin-top: -1rem;
    }
  }

  &--is-open {
    .feedback-focus__header {
      justify-content: flex-end;
    }
    .feedback-focus__tabs {
      display: grid;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -0.5rem;

    &:hover {
      color: $clr-primary;
      cursor: pointer;
    }
  }

  &__extremes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 1rem;
  }

  &__tabs {
    margin: 0;
    height: 2.5rem;

    display: none;
    grid-template-columns: 1fr 2.5fr 3.3fr 2.5fr 1fr;
    @include mobile {
      grid-template-columns: 1fr 1.2fr 1.5fr 1.2fr 1fr;
    }
  }

  &__tab {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey;
    cursor: pointer;
    font-size: 0.875rem;

    &--active {
      background-image: linear-gradient(to bottom, #ebebeb 0%, $white-ter 95%);

      border: 1px solid hsl(0, 0%, 92%);
      border-bottom: none;

      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      color: $clr-black-text;
      z-index: 2;
    }

    &--result {
      font-weight: $weight-bold;
      text-decoration: underline;
    }

    &--active {
      text-decoration: none;
    }
  }
  &__content {
    padding: 2rem 2rem 1rem;
    margin-bottom: 2rem;
    background-color: $white-ter;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    border: 1px solid hsl(0, 0%, 92%);
    border-top: none;

    position: relative;
    font-size: 0.975rem;

    h2 {
      font-weight: $weight-bold;
    }
    &--left {
      border-top-right-radius: 0.5rem;
    }
    &--center {
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }
    &--right {
      border-top-left-radius: 0.5rem;
    }

    @include mobile {
      padding: 1rem;
    }
  }

  &__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.matchscore-box {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;

  &__box {
    padding: 0.5rem;
    border-radius: 0.25rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    &--full {
      grid-column: span 20;
      background-color: $white-ter;
    }
  }
  &__total {
    background-color: $clr-green;
    grid-column: 1 / span 20;
  }
  &__sub {
    background-color: $grey-lighter;
  }
  &__scorecard {
    background-color: $clr-blue;
  }

  &__label {
    font-size: 0.875rem;
    color: $clr-grey-text;
  }
  &__value {
    color: $clr-black-text;
    font-weight: $weight-bold;
    font-size: 1.5rem;
  }
}

.readback-view {
  position: absolute;
  width: 100vw;
  top: 0;
  transition: 0.5s ease-in-out;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    max-width: 55rem;
  }
}

.readback-heading {
  position: absolute;
  height: 5rem;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
  .transition-buttons {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    .transition-button-wrapper {
      display: flex;
      button {
        display: flex;
        align-items: center;
      }
    }
  }
}

.readback-view-wrapper {
  position: relative;
  top: 6rem;
  width: 200vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.readback-box-score {
  padding: 2rem 1rem;
  margin: 1rem;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 1rem;
  transition: background-color 0.5s ease-in-out;
  &:hover {
    background-color: $clr-blue;
  }
}

.readback-hud-button {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 19;
  background-color: none;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #ebebeb;
  }
}

.itemfacts {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  &__itemfact {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
  }
  &__time {
    height: 4rem;
    width: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__timefill {
    background-color: $clr-primary;
  }
  &__correct {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
  }
  &__correct--true {
    background-color: $clr-green;
    color: $grey-dark;
  }
  &__correct--false {
    background-color: $white-ter;
    color: $clr-red;
  }
}
