.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
