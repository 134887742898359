.project-list {
  background-color: white;
  &__item {
    display: grid;
    grid-template-columns: 3rem 20rem 6rem;
    grid-column-gap: 1rem;

    padding: 0.5rem 0.5rem 0.5rem 0;
    margin: 0 0 0.5rem 0;
    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      .project-list__item-icon .icon {
        color: $grey;
      }
    }
  }
  &__item-left {
    align-self: center;
    justify-self: center;
  }
  &__item-icon {
    display: inline-flex;

    align-items: center;
    justify-content: center;

    background-color: $clr-pink;
    border-radius: 50%;

    width: 2.5rem;
    height: 2.5rem;

    box-sizing: border-box;

    color: white;

    .icon {
      font-size: 1rem;
    }
  }
  &__item-title {
    font-weight: $weight-bold;
    a {
      color: black;
    }
  }
  &__item-meta {
    color: $clr-grey-text;
    font-size: 0.875rem;

    display: flex;
    align-items: center;

    > span {
      margin-right: 1rem;
    }
    //span {
    //  margin-right: 0.5rem;
    //  &:after {
    //    content: ", ";
    //  }
    //}
    //span:last-of-type {
    //  margin-right: 0;
    //  &:after {
    //    content: "";
    //  }
    //}
  }
  &__item-right {
    align-self: center;
  }
  &__summary-count {
    display: flex;
    font-size: 1rem;
    font-weight: $weight-bold;
  }
  &__summary-result {
    flex: 0 0 3rem;
    text-align: center;

    background-color: $grey-lighter;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    //border: 2px solid $clr-green;
  }
  &__summary-respondent {
    flex: 0 0 3rem;
    text-align: center;

    background-color: $clr-blue;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 2px solid white;
  }
}

.box.status-box {
  &--open {
    background-color: $clr-green;
  }
  &--archived {
    background-color: $clr-red;
  }
}

.project {
  &__title {
    display: flex;
    justify-content: space-between;
  }
}
