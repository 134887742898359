.testtaker-experience {
  display: flex;
  flex-direction: column;
  align-items: center;
  .subtitle_small {
    margin-bottom: 1rem;
  }
  .rankings {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;
    .ranking {
      color: $white-ter;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: $clr-purple;
      &__selected {
        background-color: $clr-primary;
        font-weight: bold;
      }
    }
  }
  .message-box-container {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .message-box {
      padding: 0.5rem;
      width: 100%;
      resize: none;
    }
  }
  .mini-rankings {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    opacity: 0.5;
    .ranking {
      color: $white-ter;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background-color: $clr-purple;
      &__selected {
        background-color: $clr-primary;
        font-weight: bold;
      }
    }
  }
}
