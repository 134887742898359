.evali-spinner-wrapper {
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
}
.evali-spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: $clr-purple;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 6px;
    animation: evali-spinner1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: evali-spinner2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: evali-spinner2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: evali-spinner3 0.6s infinite;
  }

  &__message {
    margin-top: -1rem;
    color: $grey;
  }
}
@keyframes evali-spinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes evali-spinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes evali-spinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
