// BULMA
$black: $clr-black;
$red: $clr-red;
$yellow: lighten($clr-yellow, 10%);
$primary: $clr-green;
$text: $clr-black-text;
$content-heading-color: $clr-black-text;

//$control-height: 2.25rem;

$body-background-color: #fefefe;

$button-focus-border-color: $clr-pink;
$input-focus-border-color: $clr-pink;
$input-focus-box-shadow-color: rgba($clr-pink, 0.5);

$footer-padding: 4rem 1.5rem 6rem;
$footer-background-color: transparent;

$family-sans-serif: "Moderat", sans-serif;
$weight-semibold: 500;
$weight-bold: 500;
$strong-weight: 500;

$title-color: $clr-black-text;
$title-weight: $weight-bold;

$info: $clr-blue;

$navbar-item-img-max-height: 2.25rem;
