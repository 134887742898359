.evali-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.1);
  //background-color: rgba(150, 112, 249, 0.1);
  overflow-y: auto;
  display: none;

  &.is-active {
    display: block;
  }

  &__wrapper {
    width: 50rem;
    margin: 0 auto;
    background-color: #efefef;
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    overflow-y: auto;

    @media (max-width: 56rem) {
      width: 100%;
    }
  }

  &__content {
    max-width: 36rem;
    @include mobile {
      padding: 1rem 2rem;
    }
  }

  &__title {
    margin: 2rem 0;
    font-size: 2rem;
    font-weight: $weight-bold;
  }

  footer {
    margin: 4rem 0;
  }
  .modal-close {
    &::before,
    &::after {
      background-color: $clr-black;
    }
  }
}

.invite-email-preview {
  color: #51545e;
  font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  h1 {
    font-weight: bold;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 2rem;
    + p {
      margin-bottom: 1rem;
    }
  }
  a.f-fallback.button {
    padding-bottom: 1.6rem;
  }
}


.report-download-button {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background-color: white;
  cursor: pointer;
  padding: .2rem .4rem;
  & > span {
    display: flex;
    gap: .5rem;
    &:hover {
      text-decoration: underline;
    }
  }
}
