.avatar {
  width: 3rem;
  height: 3rem;
  background-color: $clr-blue;
  border-radius: 50%;

  font-weight: bold;
  color: $grey-darker;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  user-select: none;
}

.user-menu {
  cursor: pointer;
  &__container {
    position: absolute;
    opacity: 0;
    background-color: white;
    top: 56px;
    right: calc(100% - 64px);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.3);
  }
  .context-menu__item {
    color: $clr-black-text;
    height: 3rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    &:hover {
      background-color: $white-ter;
    }
  }
}

.user-list {
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  &__data {
    line-height: 1.25rem;
    margin-left: 1rem;
    width: 16rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__name {
    font-weight: $weight-bold;
  }
  &__role {
    display: flex;
    flex-direction: column;
    width: 8rem;
  }
  &__actions {
    margin-left: 2rem;
  }
}

.evaluation-user {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: $white-ter;
    border-radius: 0.5rem;
  }

  &__name {
    padding-left: 0.5rem;
  }
  &__action {
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    &:hover {
      color: $grey;
    }
  }
}
